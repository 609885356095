import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

export default function DrawerProofOfDelivery({ proofOfDelivery }) {
  return (
    <Box style={{ height: '100%' }}>
      <iframe
        title="Proof of Delivery"
        srcDoc={proofOfDelivery}
        align="center"
        height="100%"
        width="460px"
        frameBorder={0}
        scrolling="yes"
        style={{ paddingBottom: 92, marginLeft: 18 }}
      />
    </Box>
  );
}

DrawerProofOfDelivery.propTypes = {
  proofOfDelivery: PropTypes.string
};

DrawerProofOfDelivery.defaultProps = {
  proofOfDelivery: null
};
