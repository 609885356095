import { colors } from '@loggi/mar';
import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { ReactComponent as BipPackagesIcon } from 'images/bip-packages.svg';
import PropTypes from 'prop-types';
import { EMPTY_STATE_TEXTS } from '../constants';

export default function EmptyState({ isLoading, isError }) {
  if (isLoading) {
    return (
      <Grid container justify="center">
        <CircularProgress justify="center" />
      </Grid>
    );
  }

  if (isError) return null;

  return (
    <Box display="flex" flexDirection="column" gridGap={16}>
      <Box
        height={124}
        width={118}
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <BipPackagesIcon />
      </Box>
      <Box>
        <Typography variant="subtitle1">
          <strong>{EMPTY_STATE_TEXTS.TITLE}</strong>
        </Typography>
        <Typography variant="subtitle2" color={colors.smoke[700]}>
          {EMPTY_STATE_TEXTS.DESCRIPTION}
        </Typography>
      </Box>
    </Box>
  );
}

EmptyState.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired
};
