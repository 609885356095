import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import SummaryContext from 'tracking/template/summary.context';

import { UsersManagementContext } from 'users-management/users-management.context';

import { getCompanyType } from 'auth/access-control/access-control.service';
import { useFsRealtimeGeneral } from 'infra/firebase/realtime/firebase-realtime-database';
import { SWITCHES_REALTIME } from 'view/constants';
import { ITEMS_PER_PAGE_THIRTY } from 'shared/pagination';
import DriversListComponent from './drivers-list.component';
import { TEXTS } from './drivers-list.constants';
import { useDriversList } from '../hooks/use-drivers-list';

const DriversListContainer = ({
  hasDc,
  onSelectDriver,
  onNewRegister,
  shouldUpdate,
  shouldEdit,
  itemsPerPage
}) => {
  const [toggleUpdate, setToggleUpdate] = useState(shouldUpdate);
  const { setSummary } = useContext(SummaryContext);
  const { setCardContent } = useContext(UsersManagementContext);
  const {
    drivers,
    driversCurrentPage,
    loading,
    pagination,
    refetch,
    changePage
  } = useDriversList(itemsPerPage);

  const companyType = getCompanyType();

  const disableDriverRegister = useFsRealtimeGeneral(
    SWITCHES_REALTIME.disableDriverRegister
  );

  useEffect(() => {
    if (toggleUpdate !== shouldUpdate) {
      setToggleUpdate(!toggleUpdate);
      // When toggle is changed, we need to refetch drivers
      refetch();
    }
  }, [shouldUpdate, toggleUpdate, refetch]);

  useEffect(() => {
    setSummary(() => (
      <Typography variant="h4">
        <Box fontWeight="fontWeightBold">{TEXTS.SUMMARY_TITLE}</Box>
        {!loading && (
          <Box>{TEXTS.SUMMARY_SUBTITLE(pagination.numberItems)}</Box>
        )}
        {loading && (
          <Box pt={0.5} pb={0.5}>
            <Skeleton variant="rect" width="150px" height="34px" />
          </Box>
        )}
      </Typography>
    ));
  }, [setSummary, pagination.numberItems, loading, shouldEdit]);

  useEffect(() => {
    setCardContent(
      !disableDriverRegister ? (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">
            <em>{TEXTS.NEW_REGISTER_TITLE}</em>
          </Typography>
          <Button
            color="primary"
            variant="contained"
            size="medium"
            onClick={onNewRegister}
            data-testid="new-register-button"
          >
            {TEXTS.NEW_REGISTER_BUTTON}
          </Button>
        </Box>
      ) : null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCardContent, disableDriverRegister, onNewRegister, shouldEdit]);

  if (loading) {
    return (
      <Grid container justify="center" data-testid="driver-list-loader">
        <CircularProgress justify="center" data-testid="circle-progress" />
      </Grid>
    );
  }

  return (
    <DriversListComponent
      drivers={drivers}
      driversCurrentPage={driversCurrentPage}
      hasDc={hasDc}
      onSelectDriver={onSelectDriver}
      onChangePage={changePage}
      pagination={pagination}
      companyType={companyType}
    />
  );
};

DriversListContainer.propTypes = {
  hasDc: PropTypes.bool.isRequired,
  shouldUpdate: PropTypes.bool.isRequired,
  onSelectDriver: PropTypes.func.isRequired,
  onNewRegister: PropTypes.func.isRequired,
  shouldEdit: PropTypes.bool.isRequired,
  itemsPerPage: PropTypes.number
};

DriversListContainer.defaultProps = {
  itemsPerPage: ITEMS_PER_PAGE_THIRTY
};

export default DriversListContainer;
