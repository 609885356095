import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  input: {
    width: '100%',
    height: '56px',
    padding: '0px 20px'
  },
  bolder: {
    fontWeight: 700
  },
  footer: {
    right: 0,
    bottom: 0,
    position: 'fixed'
  }
}));

export default useStyles;
