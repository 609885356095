import getPackage from 'app/get-package';
import { assign, Machine, spawn, send } from 'xstate';
import changeDelivererMachine from './drawer-change-deliverer/drawer-change-deliverer.machine';
import refuseMachine from './drawer-refuse/drawer-refuse.machine';
import updateAddressMachine from './drawer-update-address/drawer-update-address.machine';
import updateStatusMachine from './drawer-update-status/drawer-update-status.machine';
import { ADDRESS_TYPES } from './drawer-update-address/drawer-update-address.constants';
import { TEXT, HIERARCHICAL_FALLBACK_LEVEL_UNUSED } from './drawer.constants';
import packageDetail from './drawer.service';

export const STATES = {
  syncing: 'syncing',
  detail: 'detail',
  history: 'history',
  failure: 'failure',
  refuse: 'refuse',
  final: 'final',
  changeDeliverer: 'changeDeliverer',
  updateStatus: 'updateStatus',
  changeAddress: 'changeAddress',
  proofOfDelivery: 'proofOfDelivery',
  disputeProtocol: 'disputeProtocol',
  howToUseDispute: 'howToUseDispute',
  resolveDispute: 'resolveDispute'
};

export const ACTIONS = {
  RETRY: 'retry',
  HISTORY: 'goToHistory',
  BACK: 'backToDetail',
  CONFIRMATION_BACK: 'confirmationBackToDetail',
  REFUSE: 'goToRefuse',
  CHANGE_DELIVERER: 'goToChangeDeliverer',
  UPDATE_STATUS: 'goToUpdateStatus',
  SET_NOTIFICATION: 'setNotification',
  RELOAD: 'reloadDrawer',
  RELOAD_PAGE: 'reloadPage',
  CLOSED_SNACKBAR: 'closedSnackbar',
  HIDE_ICON: 'hideIcon',
  GOOGLE_ANALITYCS: 'googleAnalitycs',
  CHANGE_ADDRESS: 'goToChangeAddress',
  REMOVE_QUALITY_FLAGS: 'removeQualityFlags',
  PROOF_OF_DELIVERY: 'goToProofOfDelivery',
  DISPUTE_PROTOCOL: 'goToDisputeProtocol',
  HOW_TO_USE_DISPUTE: 'goToHowToUseDispute',
  RESOLVE_DISPUTE: 'resolveDispute'
};

const drawerMachine = Machine(
  {
    id: 'drawer',
    initial: STATES.syncing,
    context: {
      packageId: null,
      packageInfo: null,
      areQualityFlagsRemoved: false,
      notificationMessage: '',
      showNotification: false,
      error: false,
      reloadPage: false,
      hideIcon: false,
      closeDrawer: false,
      // machine refs
      refuseMachineRef: null,
      changeDelivererMachineRef: null,
      updateStatusMachineRef: null,
      updateAddressMachineRef: null,
      // googleAnalitycs
      urlBase: '',
      userEmail: ''
    },
    states: {
      [STATES.syncing]: {
        invoke: {
          id: 'getPackage',
          src: context => {
            if (context.usePacktrack) {
              return packageDetail(context.packageId, context.loggiKey);
            }
            return getPackage(context.packageId, context.userEmail);
          },
          onDone: {
            target: STATES.detail,
            actions: assign({
              packageInfo: (_, event) => event.data,
              areQualityFlagsRemoved: () => false
            })
          },
          onError: {
            target: STATES.failure,
            actions: assign({
              error: (_, event) => {
                return event.data.message;
              },
              showNotification: (_, event) => {
                return event.data.status === 404;
              },
              notificationMessage: () => TEXT.MESSAGE_NOTFOUND_PKG_LSD,
              closeDrawer: (_, event) => {
                return event.data.status === 404;
              }
            })
          }
        }
      },
      [STATES.failure]: {
        on: {
          [ACTIONS.RETRY]: STATES.syncing
        }
      },
      [STATES.detail]: {
        on: {
          [ACTIONS.HISTORY]: STATES.history,
          [ACTIONS.RETRY]: STATES.syncing,
          [ACTIONS.REFUSE]: STATES.refuse,
          [ACTIONS.CHANGE_DELIVERER]: STATES.changeDeliverer,
          [ACTIONS.UPDATE_STATUS]: STATES.updateStatus,
          [ACTIONS.CHANGE_ADDRESS]: STATES.changeAddress,
          [ACTIONS.HOW_TO_USE_DISPUTE]: STATES.howToUseDispute,
          [ACTIONS.RESOLVE_DISPUTE]: STATES.resolveDispute,
          [ACTIONS.REMOVE_QUALITY_FLAGS]: {
            actions: assign({
              packageInfo: context => {
                const qualityFlags = {
                  suspiciousGeocoding: false,
                  hierarchicalFallbackLevel:
                    context?.packageInfo.loggiAddress.qualityFlags
                      .hierarchicalFallbackLevel
                };

                qualityFlags.hierarchicalFallbackLevel = HIERARCHICAL_FALLBACK_LEVEL_UNUSED;

                const updatedPackageInfo = context?.packageInfo;
                updatedPackageInfo.loggiAddress.qualityFlags = qualityFlags;
                return updatedPackageInfo;
              },
              areQualityFlagsRemoved: () => true
            })
          },
          [ACTIONS.PROOF_OF_DELIVERY]: STATES.proofOfDelivery,
          [ACTIONS.DISPUTE_PROTOCOL]: STATES.disputeProtocol
        }
      },
      [STATES.history]: {
        on: {
          [ACTIONS.BACK]: STATES.detail
        }
      },
      [STATES.howToUseDispute]: {
        on: {
          [ACTIONS.BACK]: STATES.detail
        }
      },
      [STATES.resolveDispute]: {
        on: {
          [ACTIONS.BACK]: STATES.detail
        }
      },
      [STATES.proofOfDelivery]: {
        on: {
          [ACTIONS.BACK]: STATES.detail
        }
      },
      [STATES.disputeProtocol]: {
        on: {
          [ACTIONS.BACK]: STATES.detail
        }
      },
      [STATES.refuse]: {
        entry: ['spawnRefuseMachine'],
        on: {
          [ACTIONS.BACK]: STATES.detail,
          [ACTIONS.RELOAD_PAGE]: STATES.final
        }
      },
      [STATES.changeDeliverer]: {
        entry: ['spawnChangeDelivererMachine'],
        on: {
          [ACTIONS.BACK]: STATES.detail,
          [ACTIONS.RELOAD_PAGE]: STATES.final
        }
      },
      [STATES.updateStatus]: {
        entry: ['spawnUpdateStatusMachine'],
        on: {
          [ACTIONS.BACK]: STATES.detail,
          [ACTIONS.RELOAD_PAGE]: STATES.final
        }
      },
      [STATES.changeAddress]: {
        entry: ['spawnUpdateAddressMachine'],
        on: {
          [ACTIONS.BACK]: {
            actions: send(
              { type: 'parentTryCancel' },
              { to: context => context.updateAddressMachineRef }
            )
          },
          [ACTIONS.CONFIRMATION_BACK]: STATES.detail,
          [ACTIONS.RELOAD_PAGE]: STATES.final
        }
      },
      [STATES.final]: {
        entry: [
          'cleanURL',
          assign({
            reloadPage: true
          })
        ],
        type: 'final'
      }
    },
    on: {
      [ACTIONS.RELOAD]: {
        target: STATES.syncing,
        actions: assign({ packageId: (_, event) => event.value })
      },
      [ACTIONS.SET_NOTIFICATION]: {
        actions: assign({
          notificationMessage: (_, event) => event.data.message,
          error: (_, event) => event.data.error,
          showNotification: true
        })
      },
      [ACTIONS.CLOSED_SNACKBAR]: {
        actions: assign({
          showNotification: false
        })
      },
      [ACTIONS.HIDE_ICON]: {
        actions: assign({
          hideIcon: (_, event) => event.value
        })
      },
      [ACTIONS.GOOGLE_ANALITYCS]: {
        actions: [
          assign({ urlBase: () => window.location.pathname }),
          (_, event) => {
            const actualURL = window.location.pathname;
            window.history.pushState(null, '', `${actualURL}${event.data.url}`);
          }
        ]
      }
    }
  },
  {
    actions: {
      spawnRefuseMachine: assign({
        refuseMachineRef: context =>
          spawn(
            refuseMachine.withContext({
              packageId: context.packageId,
              barcode: context.packageInfo.integrationInfo.barcode
            }),
            { sync: true }
          )
      }),
      spawnChangeDelivererMachine: assign({
        changeDelivererMachineRef: context =>
          spawn(
            changeDelivererMachine.withContext({
              packageId: context.packageId,
              barcode: context.packageInfo.integrationInfo.barcode
            }),
            { sync: true }
          )
      }),
      spawnUpdateStatusMachine: assign({
        updateStatusMachineRef: context =>
          spawn(
            updateStatusMachine.withContext({
              packageId: context.packageId,
              packageInfo: context.packageInfo
            }),
            { sync: true }
          )
      }),
      spawnUpdateAddressMachine: assign({
        updateAddressMachineRef: context =>
          spawn(
            updateAddressMachine.withContext({
              packageId: context.packageId,
              packageInfo: context.packageInfo,
              selectedAddressType: ADDRESS_TYPES.loggi
            }),
            { sync: true }
          )
      }),
      cleanURL: context => {
        window.history.pushState(null, '', context.urlBase);
      }
    }
  }
);

export default drawerMachine;
