import React from 'react';
import { Typography, Box } from '@material-ui/core';
import useStyles from '../../../view/molecules/package-drawer/drawer.style';
import { TEXT } from '../../../view/molecules/package-drawer/drawer.constants';

export default function DrawerHowToUseDispute() {
  const classes = useStyles();

  return (
    <Box mx={3.5} pt={2.5} my={5}>
      <Typography variant="h6" className={classes.bolder}>
        {TEXT.HOW_TO_USE_DISPUTE}
      </Typography>

      <Box mt={3}>
        <Typography variant="subtitle2" className={classes.semiBolder} mb={1}>
          {TEXT.HOW_TO_USE_DISPUTE_SUB_TITLE_1}
        </Typography>
        <Box mt={1} />
        <Typography variant="body2" color="textSecondary">
          {TEXT.HOW_TO_USE_DISPUTE_DESCRIPTION_1}
        </Typography>
      </Box>

      <Box mt={3}>
        <Typography variant="subtitle2" className={classes.semiBolder} mb={1}>
          {TEXT.HOW_TO_USE_DISPUTE_SUB_TITLE_2}
        </Typography>
        <Box mt={1} />
        <Typography variant="body2" color="textSecondary">
          {TEXT.HOW_TO_USE_DISPUTE_DESCRIPTION_2}
        </Typography>
      </Box>

      <Box mt={3}>
        <Typography variant="subtitle2" className={classes.semiBolder} mb={1}>
          {TEXT.HOW_TO_USE_DISPUTE_SUB_TITLE_3}
        </Typography>
        <Box mt={1} />
        <Typography variant="body2" color="textSecondary">
          {TEXT.HOW_TO_USE_DISPUTE_DESCRIPTION_3}
        </Typography>
      </Box>
    </Box>
  );
}

DrawerHowToUseDispute.propTypes = {};

DrawerHowToUseDispute.defaultProps = {};
