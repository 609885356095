const TEXT = {
  BUTTON_CONTINUE: 'Continuar',
  GROUP: 'GRUPO',
  GROUP_PACKAGES: 'Grupo criado na base',
  IN_BASE: '- Na base',
  PACKAGES_ADDED: 'pacotes adicionados',
  BTN_UNGROUP: 'Desagrupar grupo',
  BTN_ACTIONS_GROUP: 'Ações do grupo',
  DIRECT_CIRCLE: 'Direcionar para círculo',
  EDIT_CIRCLE: 'Alterar círculo',
  ASSOCIATE_DELIVERER: 'Distribuir para entregador',
  DELIVERER_ASSOCIATION_SIZE_WARNING:
    'Aguarde um momento, essa operação pode demorar alguns segundos devido a quantidade de pacotes sendo processados.',
  DELIVERER_ASSOCIATION_ENVOY_WARNING:
    'Entregador não cadastrado na Envoy, para evitar que sua operação seja afetada, faça-o o quanto antes.',
  DELIVERER_ASSOCIATION_SUCCESS: 'Pacotes distribuidos com sucesso',
  ERROR_MESSAGE_SEARCH: 'Eita! não encontramos nenhum ',
  ERROR_MESSAGE_SEARCH_WHERE: ' na sua lista de entregadores',
  TO: 'Direcionado para',
  CIRCLE: 'Círculo',
  CIRCLE_DESCRIBE: 'Escolha um círculo para direcionar o grupo de pacotes.',
  BTN_CONFIRMATION_DIRECT_CIRCLE: 'Direcionar grupo de pacotes',
  SNACK_BAR_SUCESS_DIRECT_CIRCLE: 'Pronto, grupo direcionado para círculo',
  SNACK_BAR_UNDO_SUCESS_DIRECT:
    'Pronto, o direcionamento do grupo de pacotes foi cancelado.',
  CANCEL_DIRECT: 'Cancelar direcionamento',
  SAVE: 'Salvar',
  DISASSOCIATION_CONFIRM_TITLE: 'Você quer desagrupar os pacotes?',
  DISASSOCIATION_CONFIRM_SUBTITLE:
    'Os pacotes serão movidos para a estação de processamento como pacotes avulsos e poderão ser agrupados novamente.',
  DISASSOCIATION_CONFIRM_BUTTON: 'Quero desagrupar os pacotes',
  DISASSOCIATION_CANCEL_BUTTON: 'Voltar',
  DISASSOCIATION_SUCCESS: 'Pronto, pacotes desagrupados!',
  GROUP_PACKAGES_DEFAULT: 'Grupo de pacotes',
  TO_TRANSFER: 'Para transferir',
  PACKAGES: 'pacotes',
  PRINT_GROUP_TICKET: 'Imprimir etiqueta do grupo',
  EDIT_GROUP: 'Editar pacotes',
  EDIT_KEYWORDS: 'Editar palavras-chave'
};

export const SEP_CONTEXT = 'SEP';

export const DIALOG = {
  CANCEL_DIRECT: {
    TITLE: 'Você quer cancelar o direcionamento?',
    DESCRIPTION:
      'Verifique se os pacotes ainda não saíram da sua base. Ao cancelar o direcionamento, o grupo deixa de aparecer para a operação do círculo.',
    LABEL_BUTTON_LEFT: 'Voltar',
    LABEL_BUTTON_RIGHT: 'Quero cancelar o direcionamento'
  }
};

export const LICENSE_PLATE_NOT_FOUND = licensePlate =>
  `O lincense plate #${licensePlate} não é valido. Tente outra vez.`;

export const VIEWS_DRAWER = {
  PRINT_LABEL: 'PRINT_LABEL',
  DETAIL: 'DETAIL',
  CIRCLES: 'CIRCLES',
  KEYWORDS: 'KEYWORDS',
  DELIVERER: 'DELIVERER',
  EDIT_GROUP: 'EDIT_GROUP',
  EDIT_KEYWORDS: 'EDIT_KEYWORDS'
};

export default TEXT;
