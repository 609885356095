import { colors } from '@loggi/mar';
import { FILTERS } from 'shared/filter/filter.constants';

const DISPUTE_STATUS_PENDING = 'pending';
const DISPUTE_STATUS_NON_PENDENCIES = 'non-pendencies';

const DISPUTE_DELIVERER_FILTER = {
  key: 'Quem entregou',
  btnFilter: 'Quem entregou',
  keyFilter: 'deliverer',
  whichFilter: FILTERS.DELIVERER_INCLUDING_INACTIVE,
  propertyFilter: 'deliverers'
};

const DISPUTE_RESPONSIBLE_FILTER = {
  key: 'Quem resolveu',
  btnFilter: 'Quem resolveu',
  keyFilter: 'dispute_responsible',
  whichFilter: FILTERS.DELIVERER_INCLUDING_INACTIVE,
  propertyFilter: 'deliverers'
};

const DISPUTE_RESPONSE_FILTER = {
  key: 'Resposta',
  btnFilter: 'Resposta',
  keyFilter: 'status',
  whichFilter: FILTERS.DISPUTES_STATUS,
  propertyFilter: 'solvedStatuses'
};

const DISPUTES_MAPPER = {
  [DISPUTE_STATUS_NON_PENDENCIES]: {
    TITLE: {
      finished: 'Acareações / ',
      disputes: 'Encerradas'
    },
    DISPUTE_BUTTON_FILTERS: [
      {
        id: 'to_allocate',
        label: 'ninguém alocado',
        keyFilter: 'without_dispute_responsible',
        keyValues: ['true']
      }
    ],
    DISPUTE_DROP_FILTERS: [
      DISPUTE_DELIVERER_FILTER,
      DISPUTE_RESPONSIBLE_FILTER,
      DISPUTE_RESPONSE_FILTER
    ]
  },
  [DISPUTE_STATUS_PENDING]: {
    TITLE: {
      finished: 'Acareações / ',
      disputes: 'Em aberto'
    },
    DISPUTE_BUTTON_FILTERS: [
      {
        id: 'to_expire',
        label: 'a expirar',
        keyFilter: 'range_date',
        keyValues: []
      },
      {
        id: 'to_allocate',
        label: 'ninguém alocado',
        keyFilter: 'without_dispute_responsible',
        keyValues: ['true']
      }
    ],
    DISPUTE_DROP_FILTERS: [DISPUTE_DELIVERER_FILTER]
  }
};

const MAPPER_STATUS = {
  Uninformed: {
    style: {
      backgroundColor: colors.red[100],
      fontWeight: 700,
      color: colors.smoke[900]
    },
    text: 'inválido'
  },
  'Dispute Expiring': {
    style: {
      backgroundColor: colors.yellow[500],
      fontWeight: 700,
      color: colors.smoke[900]
    },
    text: 'a expirar'
  },
  'Dispute Solved': {
    style: {
      backgroundColor: colors.green[500],
      fontWeight: 700,
      color: colors.smoke[100]
    },
    text: 'resolvido'
  },
  'Dispute Expired': {
    style: {
      backgroundColor: colors.red[100],
      fontWeight: 700,
      color: colors.smoke[900]
    },
    text: 'expirado'
  }
};

const TEXT = {
  NONE_PACKAGE: 'Nenhum pacote',
  PACKAGE: 'pacote',
  RESOLVE_DISPUTE_BUTTON: 'Identificar pacote'
};

const DISPUTE_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';

const ERROR_TEXT = 'Erro ao buscar disputas';

const HIGHLIGHT_TEXTS = {
  expired: {
    title: 'O prazo para confirmar essa entrega expirou',
    subtitle: `A confirmação não foi enviada até $deadline e por isso o pacote foi extraviado. Tem algo errado? Fale com o suporte na Central de Ajuda.`
  },
  pendingWithResponsible: {
    title: 'Aguardando confirmação da entrega',
    subtitle: `As instruções estão disponíveis no aplicativo do entregador $responsible. O prazo para confirmar a entrega é `,
    deadline: `até $deadline.`
  },
  pedingWithoutResponsible: {
    title: 'Essa entrega precisa ser confirmada',
    subtitle: `Um entregador precisa confirmar a entrega com o destinatário `,
    deadline: `até $deadline.`
  },
  solved: {
    title: 'O entregador confirmou essa entrega',
    subtitle: `A confirmação foi enviada no prazo. Tem alguma dúvida? Fale com o suporte na Central de Ajuda.`
  },
  pendingModality2: {
    title: 'Confirme a localização do pacote',
    subtitle:
      'Caso esteja em sua posse bipe o pacote dentro do prazo. **Caso o pacote não seja identificado até $deadline**, ele será considerado como extraviado.\n\nSe não estiver mais de posse do pacote, confira o comprovante de entrega e fale com o suporte na Central de Ajuda.'
  },
  expiredModality2: {
    title: 'O pacote não foi identificado',
    subtitle:
      'A identificação do pacote não foi enviada até $deadline e por isso o pacote foi extraviado.\n\nTem algo errado? Confira o comprovante de entrega e fale com o suporte na Central de Ajuda.'
  },
  solvedModality2: {
    title: 'A identificação do pacote foi realizada com sucesso',
    subtitle:
      'A identificação do pacote foi realizada no dia **$solved_date**.\n\nTem algo errado? Fale com o suporte na Central de Ajuda.'
  }
};

const MODALITY_DATA_BY_SITUATION = {
  DISPUTE_SITUATION_TYPE_EXPIRED: {
    title: HIGHLIGHT_TEXTS.expiredModality2.title,
    subtitle: HIGHLIGHT_TEXTS.expiredModality2.subtitle,
    deadline: '',
    backGroundColor: colors.red[50]
  },
  DISPUTE_SITUATION_TYPE_SOLVED: {
    title: HIGHLIGHT_TEXTS.solvedModality2.title,
    subtitle: HIGHLIGHT_TEXTS.solvedModality2.subtitle,
    backGroundColor: colors.green[50]
  },
  DISPUTE_SITUATION_TYPE_PENDING: {
    title: HIGHLIGHT_TEXTS.pendingModality2.title,
    subtitle: HIGHLIGHT_TEXTS.pendingModality2.subtitle,
    backGroundColor: colors.yellow[50]
  },
  DISPUTE_SITUATION_TYPE_UNASSIGNED: {
    title: HIGHLIGHT_TEXTS.pendingModality2.title,
    subtitle: HIGHLIGHT_TEXTS.pendingModality2.subtitle,
    backGroundColor: colors.yellow[50]
  },
  DISPUTE_SITUATION_INVALID: {
    title: HIGHLIGHT_TEXTS.pendingModality2.title,
    subtitle: HIGHLIGHT_TEXTS.pendingModality2.subtitle,
    backGroundColor: colors.yellow[50]
  }
};

const PENDING_DISPUTE_SITUATIONS = [
  'DISPUTE_SITUATION_TYPE_PENDING',
  'DISPUTE_SITUATION_TYPE_UNASSIGNED',
  'DISPUTE_SITUATION_INVALID'
];

const DRIVER_LIST_TEXTS = {
  formLabel: 'Selecione um entregador',
  cancelButton: 'Cancelar',
  confirmButton: 'Confirmar'
};

const INACTIVE_DRIVER = 'Entregador indisponível';

const UPDATE_RESPONSIBLE_TEXTS = {
  cancelButton: 'Cancelar',
  confirmButton: 'Confirmar',
  buttonWithResponsible: 'Alterar entregador',
  buttonWithoutResponsible: 'Alocar entregador',
  buttonWithoutResponsibleAssignSelf: 'Alocar este entregador',
  buttonWithoutResponsibleAssignOther: 'Alocar outro entregador',
  labelSuccessDriverAllocation: 'Entregador alocado com sucesso!',
  labelErrorDriverAllocation: 'Erro na alocação do entregador!',
  labelErrorGetDrivers: 'Erro na busca de entregadores',
  textDriverResponsible: 'O pacote foi entregue por:',
  textDisputeResponsible: 'Quem vai resolver:'
};

const DEFAULT_DISPUTE_PER_PAGE = 20;
const DISPUTE_HELP_CENTER_LINK =
  'https://ajuda.loggi.com/hc/pt-br/requests/new';

export {
  TEXT,
  ERROR_TEXT,
  MAPPER_STATUS,
  DISPUTE_DATE_FORMAT,
  HIGHLIGHT_TEXTS,
  DRIVER_LIST_TEXTS,
  UPDATE_RESPONSIBLE_TEXTS,
  DEFAULT_DISPUTE_PER_PAGE,
  DISPUTES_MAPPER,
  DISPUTE_STATUS_PENDING,
  DISPUTE_DELIVERER_FILTER,
  DISPUTE_RESPONSIBLE_FILTER,
  DISPUTE_STATUS_NON_PENDENCIES,
  DISPUTE_RESPONSE_FILTER,
  INACTIVE_DRIVER,
  PENDING_DISPUTE_SITUATIONS,
  MODALITY_DATA_BY_SITUATION,
  DISPUTE_HELP_CENTER_LINK
};
