import { Box, Typography } from '@material-ui/core';
import StatusColorDescription from 'shared/status-color-description';
import React, { useMemo } from 'react';
import {
  MAPPER_STATUS,
  STATUS_DISPUTE_PACKAGE_FOUND,
  STATUS_DISPUTE_PACKAGE_NOT_FOUND
} from 'shared/status-color-description/status-color-description.constants';
import PropTypes from 'prop-types';

import { PENDING_DISPUTE_SITUATIONS } from 'tracking/disputes/disputes.constants';
import { TEXT } from '../../drawer.constants';

const mapSituationToStatus = {
  DISPUTE_SITUATION_TYPE_PENDING: STATUS_DISPUTE_PACKAGE_NOT_FOUND,
  DISPUTE_SITUATION_TYPE_UNASSIGNED: STATUS_DISPUTE_PACKAGE_NOT_FOUND,
  DISPUTE_SITUATION_INVALID: STATUS_DISPUTE_PACKAGE_NOT_FOUND,
  DISPUTE_SITUATION_TYPE_EXPIRED: STATUS_DISPUTE_PACKAGE_NOT_FOUND,
  DISPUTE_SITUATION_TYPE_SOLVED: STATUS_DISPUTE_PACKAGE_FOUND
};

export default function HeaderDisputeSection({
  packageInfo,
  situation,
  disputeCreatedDate,
  disputeSolvedDate,
  isDisputeSolved
}) {
  const isPending = PENDING_DISPUTE_SITUATIONS.includes(situation);
  const showTitle = isPending || isDisputeSolved;

  const statusProps = useMemo(() => {
    const status = mapSituationToStatus[situation];
    if (status) {
      return { status, statusDescription: MAPPER_STATUS[status].text };
    }
    return {
      status: packageInfo.status.code,
      statusDescription: packageInfo.statusDisplay
    };
  }, [packageInfo, situation]);

  return (
    <Box p={3.5} pt={0} display="flex" flexDirection="column" gridGap={8}>
      <StatusColorDescription
        status={statusProps.status}
        statusDescription={statusProps.statusDescription}
        typographyVariant="body1"
      />
      {showTitle && (
        <Typography variant="h6">
          <strong>
            {isPending ? TEXT.DISPUTE_PROCESS : TEXT.FINISHED_DISPUTE}
          </strong>
        </Typography>
      )}
      <Box p={0}>
        {!!disputeCreatedDate && (
          <Typography variant="subtitle2">
            {`${TEXT.ARRIVED}${disputeCreatedDate}`}
          </Typography>
        )}
        {isDisputeSolved && (
          <Typography variant="subtitle2">
            {`${TEXT.FINISHED}${disputeSolvedDate}`}
          </Typography>
        )}
      </Box>
      {!!packageInfo.regionLabel && (
        <Box pt={1}>
          <Typography variant="subtitle2">{packageInfo.regionLabel}</Typography>
        </Box>
      )}
    </Box>
  );
}

HeaderDisputeSection.propTypes = {
  packageInfo: PropTypes.shape().isRequired,
  situation: PropTypes.string.isRequired,
  disputeCreatedDate: PropTypes.string.isRequired,
  disputeSolvedDate: PropTypes.string.isRequired,
  isDisputeSolved: PropTypes.bool.isRequired
};
