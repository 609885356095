import React from 'react';
import { Box, Chip, Typography, Tooltip } from '@material-ui/core';
import { get } from 'tracking/tracking.configuration';
import { formatDate } from 'finance/finance.configuration';
import { colors } from '@loggi/mar';
import moment from 'moment';
import InfoIcon from '@material-ui/icons/Info';
import {
  MAPPER_STATUS,
  DISPUTE_STATUS_PENDING,
  DISPUTE_STATUS_NON_PENDENCIES
} from './disputes.constants';

function isExpiring(date) {
  const deadlineDate = moment(date);
  const now = moment();
  const oneDayFromNow = moment().add(24, 'hours');

  return deadlineDate.isBetween(now, oneDayFromNow);
}

const createColumnConfig = (header, component) => ({
  header,
  component,
  headAlign: 'left',
  propsStyleBody: { align: 'left' }
});

const deadline = {
  header: 'Resolver até',
  orderBy: 'deadline'
};

const deadlineColumn = createColumnConfig(deadline.header, item =>
  formatDate(item?.deadline, 'DD MMM [às] HH:mm')
);

const deliverer = {
  header: 'Entregador',
  orderBy: 'deliverer'
};

const delivererColumn = createColumnConfig(deliverer.header, item =>
  get(item, 'deliverer', '-')
);

const renderDisputeResponsible = (item, isEnvoyDisputesActive) => {
  const disputeResponsibleName = get(item, 'disputeResponsible', false);

  if (isEnvoyDisputesActive) {
    return (
      <Box display="flex" alignItems="center" flexDirection="row" gridGap={8}>
        <Typography variant="body2">Bipe o pacote</Typography>

        <Tooltip
          title="O pacote deve ser bipado para iniciar a análise de dados da entrega e resolver a acareação junto à Loggi."
          placement="top"
          arrow
        >
          <InfoIcon fontSize="small" color="primary" />
        </Tooltip>
      </Box>
    );
  }

  // eslint-disable-next-line no-unneeded-ternary
  return (
    disputeResponsibleName || (
      <Typography style={{ color: colors.root[500] }} variant="body2">
        ninguém alocado
      </Typography>
    )
  );
};

const disputeResponsible = {
  header: 'Quem vai resolver',
  orderBy: 'dispute_responsible',
  action: 'Ação'
};

const disputeResponsibleColumn = isEnvoyDisputesActive =>
  createColumnConfig(
    isEnvoyDisputesActive
      ? disputeResponsible.action
      : disputeResponsible.header,
    item => renderDisputeResponsible(item, isEnvoyDisputesActive)
  );

const renderPackageInfo = item => {
  const companyName = get(item, 'packageCompanyName', '-');
  const barcode = get(item, 'packageBarcode', '-');

  return (
    <Box>
      <Box>{companyName}</Box>
      <Typography style={{ color: colors.root[500] }} variant="caption">
        {barcode}
      </Typography>
    </Box>
  );
};

const packageInfo = {
  header: 'Pacote',
  orderBy: 'company'
};

const packageInfoColumn = createColumnConfig(
  packageInfo.header,
  renderPackageInfo
);

export const renderTranslatedStatus = item => {
  let status = get(item, 'translatedStatus', '-');

  if (status === 'Dispute Pending') {
    const itemDeadline = get(item, 'deadline', '');
    if (isExpiring(itemDeadline)) {
      status = 'Dispute Expiring';
    }
  }

  const statusMap = MAPPER_STATUS[status];

  return statusMap ? (
    <Chip style={statusMap.style} size="small" label={statusMap.text} />
  ) : null;
};

const translatedStatusSolvedTabColumn = createColumnConfig(
  'Resposta',
  renderTranslatedStatus
);

const translatedStatusPendingTabColumn = createColumnConfig(
  '',
  renderTranslatedStatus
);

const openIn = {
  header: 'Aberto em',
  orderBy: 'created'
};

const openInColumn = createColumnConfig(openIn.header, item =>
  formatDate(item?.created, 'DD MMM [às] HH:mm')
);

export default function getConfigurableTableData(
  disputeStatus,
  isEnvoyDisputesActive
) {
  const MAPPER_TABLE_CONFIG = {
    [DISPUTE_STATUS_PENDING]: {
      openIn: openInColumn,
      packageBarcode: packageInfoColumn,
      deliverer: delivererColumn,
      disputeResponsible: disputeResponsibleColumn(isEnvoyDisputesActive),
      deadline: deadlineColumn,
      translatedStatus: translatedStatusPendingTabColumn
    },
    [DISPUTE_STATUS_NON_PENDENCIES]: {
      openIn: openInColumn,
      packageBarcode: packageInfoColumn,
      deliverer: delivererColumn,
      disputeResponsible: disputeResponsibleColumn(isEnvoyDisputesActive),
      deadline: deadlineColumn,
      translatedStatus: translatedStatusSolvedTabColumn
    }
  };

  return MAPPER_TABLE_CONFIG[disputeStatus];
}

export const ORDER_BY_VIEW_DISPUTES = [
  openIn,
  packageInfo,
  deliverer,
  disputeResponsible,
  deadline
];

export const DEFAULT_ORDER_BY_VIEW_DISPUTES = deadline;
