import { Box, TextField } from '@material-ui/core';
import React from 'react';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { IDENTIFY_PACKAGE_DISPUTE_TEXTS } from '../constants';
import { useStyles } from '../styles';

export default function BarcodeForm({
  barcode,
  changeBarcode,
  onSubmit,
  error
}) {
  const classes = useStyles();

  return (
    <form onSubmit={onSubmit}>
      <TextField
        variant="outlined"
        placeholder={IDENTIFY_PACKAGE_DISPUTE_TEXTS.PLACEHOLDER}
        required
        fullWidth
        onChange={changeBarcode}
        value={barcode}
        inputProps={{
          minLength: 3,
          className: classes.input,
          'data-testid': 'barcode-input'
        }}
        onKeyPress={event => {
          if (event.key === 'Enter') return;
          changeBarcode(event);
        }}
        autoFocus
      />
      {!!error && (
        <Box mt={2}>
          <Alert variant="standard" severity="error">
            {error}
          </Alert>
        </Box>
      )}
    </form>
  );
}

BarcodeForm.propTypes = {
  barcode: PropTypes.string.isRequired,
  changeBarcode: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired
};
