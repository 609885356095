import movePackageListService from 'infra/service/move-package-list';
import db from 'infra/storage/async-elasticsearch-database';
import { packageCacheModel } from 'infra/storage/async-elasticsearch-database.model';
import {
  getLoggedDCId,
  getLoggedLMCId,
  getSelectedRoutingCode
} from 'auth/login/login.service';

import { STATUS_ERROR_CODE } from 'information/drawer-packages-out-of-coverage/drawer-packages-out-of-coverage.constants';

import { translateStatusCode, DEFAULT_MESSAGE } from '../httpMessage';

export const CUSTOM_ERROR_MESSAGES = {
  404: 'Não conseguimos encontrar o pacote. Tente de novo daqui a pouco ou atualize a página.',
  406: 'Opa, esse entregador não está habilitado, verifique o cadastro ou escolha outra pessoa.',
  412: 'Esse pacote já foi entregue, por isso, não é possível fazer alterações.',
  417: 'Os pacotes só podem ser recusados no momento do recebimento.',
  424: 'O status atual do pacote {} não permite a distribuição. Faça devolução para Loggi.',
  425: barcodes =>
    barcodes.length !== 0
      ? `Os seguintes pacotes estão fora da área de cobertura: ${barcodes.join(
          ', '
        )}.`
      : 'O grupo possui pacotes fora da área de cobertura.',
  428: 'Esse pacote não foi recebido na plataforma. Bipe para receber antes de distribuir.',
  429: 'Aguarde, esta mudança já está sendo processada'
};

const STATUS_ERROR_CODES = {
  INVALID_STATUS_FOR_DELIVERY: 424
};

export const ERROR_MESSAGE_REFUSED_PKG_INDIVIDUALLY =
  'Refused packages that were received individuall';
export const ERROR_MESSAGE_REFUSED_PKG_INDIVIDUALLY_PATTERN = /Refused packages that were received individuall/;

export const PARSED_ERROR_MESSAGE_REFUSED_PKG_INDIVIDUALLY =
  'Este pacote foi recebido individualmente e não pode ser recusado.';

export const ERROR_MESSAGE_DRIVER_WITHOUT_UUID_PATTERN = /Driver without uuid information/;

export const PARSED_ERROR_MESSAGE_DRIVER_WITHOUT_UUID =
  'O entregador não está ativo no novo app. Entre em contato com a Loggi.';

export const ERROR_MESSAGE_FAILED_TO_DISTRIBUTE_NUVEM_API_PATTERN = /Failed to distribute packages via NuvemAPI/;

export const PARSED_ERROR_MESSAGE_FAILED_TO_DISTRIBUTE_NUVEM_API =
  'Falha ao distribuir pacotes via NuvemAPI.';

export const ERROR_MESSAGE_CANNOT_CREATE_JOBS_WITH_LOGGI_KEYS_PATTERN = /Cannot create jobs with loggi keys (.+?)\. Keys are related to a job in progress/;

export const ERROR_LOAD_DEMAND_ALREADY_EXISTS_PATTERN = /Load demand with label (.+?) already exists/;

const ERROR_PACKAGE_STATUS_CANCELLED = /There is a package in the list with the status cancelled, so it is not possible to move/;
const PARSED_ERROR_PACKAGE_STATUS_CANCELLED =
  'Não foi possível realizar a distribuição. Os pacotes abaixo estão com status cancelado. Por favor, encaminhe-os para devolução.';

const extractLoggiKeysFromCannotCreateJobs = messageOrigin => {
  const match = messageOrigin.match(
    ERROR_MESSAGE_CANNOT_CREATE_JOBS_WITH_LOGGI_KEYS_PATTERN
  );
  return match ? match[1] : null;
};

const generateCannotCreateJobsErrorMessage = messageOrigin => {
  const loggiKeys = extractLoggiKeysFromCannotCreateJobs(messageOrigin);
  if (loggiKeys) {
    return `Não é possível criar novos jobs com as loggi_keys: ${loggiKeys}. Essas loggi_keys estão relacionadas a um job em andamento.`;
  }
  return messageOrigin;
};

const extractLabelFromLoadDemandAlreadyExists = messageOrigin => {
  const match = messageOrigin.match(ERROR_LOAD_DEMAND_ALREADY_EXISTS_PATTERN);
  return match ? match[1] : null;
};

const generateLoadDemandAlreadyExistsErrorMessage = messageOrigin => {
  const label = extractLabelFromLoadDemandAlreadyExists(messageOrigin);
  if (label) {
    return `Já existe uma demanda de carga com a etiqueta: ${label}.`;
  }
  return messageOrigin;
};

export const CUSTOM_TEXT_ERROR_MESSAGES = [
  {
    messageOrigin: ERROR_MESSAGE_DRIVER_WITHOUT_UUID_PATTERN,
    messageParsed: () => PARSED_ERROR_MESSAGE_DRIVER_WITHOUT_UUID
  },
  {
    messageOrigin: ERROR_MESSAGE_REFUSED_PKG_INDIVIDUALLY_PATTERN,
    messageParsed: () => PARSED_ERROR_MESSAGE_REFUSED_PKG_INDIVIDUALLY
  },
  {
    messageOrigin: ERROR_MESSAGE_FAILED_TO_DISTRIBUTE_NUVEM_API_PATTERN,
    messageParsed: () => PARSED_ERROR_MESSAGE_FAILED_TO_DISTRIBUTE_NUVEM_API
  },
  {
    messageOrigin: ERROR_MESSAGE_CANNOT_CREATE_JOBS_WITH_LOGGI_KEYS_PATTERN,
    messageParsed: messageOrigin =>
      generateCannotCreateJobsErrorMessage(messageOrigin)
  },
  {
    messageOrigin: ERROR_LOAD_DEMAND_ALREADY_EXISTS_PATTERN,
    messageParsed: messageOrigin =>
      generateLoadDemandAlreadyExistsErrorMessage(messageOrigin)
  },
  {
    messageOrigin: ERROR_PACKAGE_STATUS_CANCELLED,
    messageParsed: messageOrigin => {
      const data = JSON.parse(messageOrigin.detail);

      return `${PARSED_ERROR_PACKAGE_STATUS_CANCELLED} \n - ${data.package_ids.join(
        '\n - '
      )}`;
    }
  }
];

const movePackageList = (
  process,
  destination,
  packagesInfo,
  isPhyisicalEvidence,
  latitude,
  longitude,
  statusCodeOverwrite = null,
  userId = null
) =>
  movePackageListService(
    {
      process,
      destination,
      distributionCenterId: getLoggedDCId(),
      lastMileCompanyId: getLoggedLMCId(),
      userId,
      distributionCenterRoutingCode: getSelectedRoutingCode()
    },
    packagesInfo,
    isPhyisicalEvidence,
    latitude,
    longitude,
    statusCodeOverwrite
  )
    .then(response => {
      db.savePackages(
        response.data.successPackages.map(pkg =>
          packageCacheModel(
            pkg.packageId,
            pkg.sortingRecordId,
            pkg.licensePlate || ''
          )
        )
      );
      return {
        success: response.data.successPackages,
        failed: response.data.failedPackages,
        message: response.data.message
      };
    })
    .catch(error => {
      if (
        error?.response?.status === STATUS_ERROR_CODE.PACKAGES_OUT_OF_COVERAGE
      ) {
        const errorResponse = error.response;
        errorResponse.message = CUSTOM_ERROR_MESSAGES[
          STATUS_ERROR_CODE.PACKAGES_OUT_OF_COVERAGE
        ](
          errorResponse.data.flatMap(p =>
            p.barcode && p.barcode !== 'None' ? [p.barcode] : []
          )
        );
        throw errorResponse;
      }

      if (
        error?.response?.status ===
        STATUS_ERROR_CODES.INVALID_STATUS_FOR_DELIVERY
      ) {
        const barcode = error.response.data.split(':')[1].trim();
        const responseError = {
          message: CUSTOM_ERROR_MESSAGES[error.response.status].replace(
            '{}',
            barcode
          )
        };
        throw responseError;
      }
      if (error?.response?.data?.errors) {
        if (error.response.data.errors.length > 15) {
          // caso exista mais de 15 erros, multiplos pacotes nao estao com Ordem de servico criada
          // ate termos uma solucao mais user friendly, retornamos a mensagem abaixo
          const shortError = {
            message: 'Pacotes em status inválidos. Receba os pacotes novamente.'
          };
          throw shortError;
        }

        const mapper = {
          DUPLICATE_ROUTE:
            'Rota duplicada. Receba todos os pacotes e agrupe novamente.',
          DRIVER_NOT_FOUND:
            'Entregador inoperante. Entre em contato com a Loggi.',
          OS_NOT_FOUND: 'OS_NOT_FOUND',
          LOCATION_NOT_FOUND: 'LOCATION_NOT_FOUND',
          AWAITING_LOCATION: 'AWAITING_LOCATION',
          DEFAULT: 'Erro inesperado. Entre em contato com a Loggi.'
        };

        const responseError = {
          message: ''
        };

        // Agrupando erro de OS_NOT_FOUND
        const osNotFoundValues = error.response.data.errors
          .filter(e => e.code === 'OS_NOT_FOUND')
          .map(e => e.value);

        if (osNotFoundValues.length > 0) {
          responseError.message = `${
            responseError.message
          } Pacotes em status inválido. Receba e agrupe os seguintes pacotes novamente: ${osNotFoundValues.join(
            ', '
          )}.`;
        }

        // Agrupando erro de AWAITING_LOCATION
        const osAwaitingLocation = error.response.data.errors
          .filter(e => e.code === 'AWAITING_LOCATION')
          .map(e => e.value);

        if (osAwaitingLocation.length > 0) {
          responseError.message = `${
            responseError.message
          } Pacotes sendo geolocalizados: ${osAwaitingLocation.join(
            ', '
          )}. Por favor aguarde alguns minutos e tente novamente.`;
        }

        // Agrupando erro de LOCATION_NOT_FOUND
        const osLocationNotFound = error.response.data.errors
          .filter(e => e.code === 'LOCATION_NOT_FOUND')
          .map(e => e.value);

        if (osLocationNotFound.length > 0) {
          responseError.message = `${
            responseError.message
          } Pacotes com problemas de endereço. Remova-o do grupo e entre em contato com a Loggi: ${osLocationNotFound.join(
            ', '
          )}.`;
        }

        const nonGroupablesErrors = error.response.data.errors.filter(
          e =>
            e.code !== 'OS_NOT_FOUND' &&
            e.code !== 'LOCATION_NOT_FOUND' &&
            e.code !== 'AWAITING_LOCATION'
        );

        // Concateando demais erros
        responseError.message = `${
          responseError.message
        } ${nonGroupablesErrors
          .map(e =>
            Object.keys(mapper).indexOf(e.code) >= 0
              ? mapper[e.code]
              : mapper.DEFAULT
          )
          .join(' ')}`.trim();

        throw responseError;
      }

      const responseError = {
        message: error.response
          ? translateStatusCode(
              error?.response?.status,
              CUSTOM_ERROR_MESSAGES,
              error.response?.data,
              CUSTOM_TEXT_ERROR_MESSAGES
            )
          : DEFAULT_MESSAGE
      };
      throw responseError;
    });

export default movePackageList;
