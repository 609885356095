import { Box, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useResponsiveValue } from 'shared/hooks/use-responsive-value';

export const SearchInput = ({ onSubmit, value }) => {
  const inputWidth = useResponsiveValue('30%', '100%');
  const [search, setSearch] = useState(value);

  const onChange = event => {
    setSearch(event.target.value);
  };

  const handleSubmit = event => {
    event.preventDefault();
    onSubmit(search);
  };

  return (
    <Box display="flex" justifyContent="space-between" mb={3}>
      <Box display="flex" flex="1" />
      <Box width={inputWidth}>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            inputProps={{ 'data-testid': 'user-search-input' }}
            variant="outlined"
            placeholder="Busque por nome, cpf ou email..."
            onChange={onChange}
            helperText='Pressione "Enter" para buscar'
            value={search}
            onKeyPress={event => {
              if (event.key === 'Enter') return;
              onChange(event);
            }}
          />
        </form>
      </Box>
    </Box>
  );
};

SearchInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};
