import { Box, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import showSnackbar from 'shared/snackbar';
import { useSnackbar } from 'notistack';
import { playSuccess } from 'tracking/sound';
import { useFsRealtimeGeneral } from 'infra/firebase/realtime/firebase-realtime-database';
import { SWITCHES_REALTIME } from 'view/constants';
import { IDENTIFY_PACKAGE_DISPUTE_TEXTS } from './constants';
import { useStyles } from './styles';
import BarcodeForm from './components/barcode-form';
import useBarcode from './identify-package.hooks';
import resolveDisputeService from './identify-package.service';
import EmptyState from './components/empty-state';

export default function DrawerIdentifyPackageDispute({
  packageInfo,
  onResolveDispute
}) {
  const isTransformBarcodeDisabled = useFsRealtimeGeneral(
    SWITCHES_REALTIME.fsDisableTransformUpperCaseDisputeBarcode
  );
  const classes = useStyles();
  const expectedBarcode = isTransformBarcodeDisabled
    ? packageInfo?.integrationInfo?.barcode
    : packageInfo?.integrationInfo?.barcode?.toUpperCase();

  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const {
    error,
    barcode,
    changeBarcode,
    setError,
    handleInvalidBarcode
  } = useBarcode({ isTransformBarcodeDisabled });

  const handleResolveDispute = async () => {
    playSuccess();
    setIsLoading(true);
    try {
      await resolveDisputeService(barcode);
      onResolveDispute();
      showSnackbar({
        variant: 'success',
        message: IDENTIFY_PACKAGE_DISPUTE_TEXTS.DISPUTE_RESOLVED,
        enqueueSnackbar
      });
    } catch (err) {
      handleInvalidBarcode(err?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmitBarcodeForm = async event => {
    event.preventDefault();

    if (isLoading) return undefined;
    setError('');

    if (expectedBarcode !== barcode) {
      return handleInvalidBarcode();
    }

    return handleResolveDispute();
  };

  return (
    <Box display="flex" flexDirection="column" mx={3.5} mb={9.5}>
      <Box pt={2.5} my={4}>
        <Typography variant="h4" className={classes.bolder}>
          {IDENTIFY_PACKAGE_DISPUTE_TEXTS.TITLE}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" gridGap={24}>
        <BarcodeForm
          error={error}
          barcode={barcode}
          onSubmit={onSubmitBarcodeForm}
          changeBarcode={changeBarcode}
        />
        <EmptyState isLoading={isLoading} isError={!!error} />
      </Box>
    </Box>
  );
}

DrawerIdentifyPackageDispute.propTypes = {
  packageInfo: PropTypes.shape().isRequired,
  onResolveDispute: PropTypes.func.isRequired
};
