import { Box, Divider, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { formatPhoneMask } from '@loggi/components/src/one/formik-fields/phone-field-helpers';
import useStyles from 'view/molecules/package-drawer/drawer.style';
import { CurrentAddressInfo } from '../../drawer-detail/drawer-detail.component';
import { PACKAGE_DIRECTION_RETURN, TEXT } from '../../drawer.constants';

export default function AddressDisputeSection({ packageInfo }) {
  const styles = useStyles();
  const isReturnDirection = packageInfo?.direction === PACKAGE_DIRECTION_RETURN;

  return (
    <>
      <Box p={3.5}>
        <Typography variant="body2">
          <em>{`${packageInfo?.company?.name} #${
            packageInfo?.integrationInfo?.barcode
          }`}</em>
        </Typography>
        <Typography className={styles.smoke} variant="body2">
          {isReturnDirection
            ? TEXT.PACKAGE_DIRECTION_RETURNING
            : TEXT.PACKAGE_DIRECTION_DELIVERING}
        </Typography>
        <Typography className={styles.smoke} variant="body2">
          {packageInfo?.recipient?.name || '-'}{' '}
          {formatPhoneMask(packageInfo?.recipient?.phone)}
        </Typography>
        <CurrentAddressInfo packageInfo={packageInfo} />
      </Box>
      <Divider />
    </>
  );
}

AddressDisputeSection.propTypes = {
  packageInfo: PropTypes.shape().isRequired
};
