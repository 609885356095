import { useState } from 'react';
import { playError } from 'tracking/sound';
import { IDENTIFY_PACKAGE_DISPUTE_TEXTS } from './constants';

export default function useBarcode({ isTransformBarcodeDisabled = false }) {
  const [barcode, setBarcode] = useState('');
  const [error, setError] = useState('');

  const handleInvalidBarcode = (
    errorMessage = IDENTIFY_PACKAGE_DISPUTE_TEXTS.INVALID_BARCODE_ERROR
  ) => {
    playError();
    setError(errorMessage);
  };

  const changeBarcode = event => {
    const value = event?.target?.value?.replace?.(/\s/g, '');
    setBarcode(isTransformBarcodeDisabled ? value : value.toUpperCase());
  };

  return {
    barcode,
    error,
    changeBarcode,
    handleInvalidBarcode,
    setError
  };
}
