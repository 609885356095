import React from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { TEXTS } from '../drivers-list.constants';
import emptyState from '../../img/empty-state.png';

const EmptyState = ({ illustration, emptyText = TEXTS.EMPTY_STATE }) => (
  <Box
    width="100%"
    py={18}
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    justifyItems="center"
  >
    <Box mb={3}>
      {illustration || <img alt="empty state" src={emptyState} />}
    </Box>
    <Box px={10}>
      <Typography align="center" variant="subtitle2">
        {emptyText}
      </Typography>
    </Box>
  </Box>
);

EmptyState.propTypes = {
  emptyText: PropTypes.string,
  illustration: PropTypes.element
};

EmptyState.defaultProps = {
  emptyText: TEXTS.EMPTY_STATE,
  illustration: undefined
};

export default EmptyState;
