import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Paper } from '@material-ui/core';

import Pagination from 'shared/pagination';
import ConfigurableTable from 'view/molecules/configurable-table';
import { SearchInput } from 'users-management/components/search-input';

import { SHORT_LABEL_COMPANY_TYPE } from 'auth/access-control/access-control.service';
import { ReactComponent as EmptyStateSearchImage } from 'images/illustra-packages.svg';
import EmptyState from './empty-state/drivers-list-empty-state.component';
import getColumns, { getColumnsWithId } from './drivers-list.configuration';

export default function DriversListComponent(props) {
  const {
    drivers,
    driversCurrentPage,
    pagination,
    hasDc,
    onChangePage,
    onSelectDriver,
    companyType
  } = props;

  const [search, setSearch] = React.useState('');
  const filteredDrivers = useMemo(() => {
    if (!search) return driversCurrentPage;
    const searchLower = search.toLowerCase();
    const cleanedSearch = searchLower.replace(/[.|\-|+|(|)|\s]/g, '');
    return drivers?.filter(
      user =>
        user.fullName?.toLowerCase().includes(searchLower) ||
        user.email?.toLowerCase().includes(searchLower) ||
        user.deliverer?.cpf?.includes(cleanedSearch) ||
        user?.mobileNumber?.includes(cleanedSearch)
    );
  }, [drivers, driversCurrentPage, search]);

  const columns =
    companyType === SHORT_LABEL_COMPANY_TYPE.CARRIER
      ? getColumnsWithId(hasDc)
      : getColumns(hasDc);

  const renderContent = () => {
    if (!drivers?.length) {
      return <EmptyState />;
    }

    if (!filteredDrivers?.length)
      return (
        <EmptyState
          emptyText={`Nenhum resultado para a busca "${search}"`}
          illustration={<EmptyStateSearchImage />}
        />
      );

    return (
      <>
        <ConfigurableTable
          columns={columns}
          data={filteredDrivers}
          onClickRow={onSelectDriver}
        />
        <Box data-testid="drivers-list-pagination" mt={5}>
          <Pagination
            onPageChange={onChangePage}
            initialPage={pagination.currentPage}
            numberOfPages={pagination.numberOfPages}
          />
        </Box>
      </>
    );
  };

  return (
    <Box data-testid="drivers-list">
      <Paper
        component={Box}
        elevation={10}
        flexGrow={1}
        mb={6}
        minHeight={44}
        p={5.5}
      >
        <SearchInput
          value={search}
          onSubmit={newSearch => setSearch(newSearch)}
        />
        {renderContent()}
      </Paper>
    </Box>
  );
}

const userShape = PropTypes.shape({
  fullName: PropTypes.string,
  mobileNumber: PropTypes.string,
  email: PropTypes.string,
  deliverer: PropTypes.shape({
    id: PropTypes.string,
    cpf: PropTypes.string,
    transportType: PropTypes.string,
    isDriver: PropTypes.bool,
    apiKey: PropTypes.string,
    availableForOffer: PropTypes.bool,
    profilePictureThumbnailUrl: PropTypes.string,
    operationalStatus: PropTypes.string
  })
});

DriversListComponent.propTypes = {
  drivers: PropTypes.arrayOf(userShape).isRequired,
  driversCurrentPage: PropTypes.arrayOf(userShape).isRequired,
  hasDc: PropTypes.bool.isRequired,
  pagination: PropTypes.shape({
    currentPage: PropTypes.number,
    numberOfPages: PropTypes.number
  }).isRequired,
  onChangePage: PropTypes.func.isRequired,
  onSelectDriver: PropTypes.func.isRequired,
  companyType: PropTypes.string.isRequired
};
