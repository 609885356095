import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '@loggi/mar';
import { Box, Typography } from '@material-ui/core';
import useStyles from 'view/molecules/package-drawer/drawer.style';
import disputesModel from 'tracking/disputes/disputes.model';
import { dateFormatter } from 'shared/formatters/date';
import { HIGHLIGHT_TEXTS } from './disputes.constants';
import { renderTranslatedStatus } from './disputes.configuration';

const renderSubtitle = subtitle => {
  if (!subtitle?.includes('**')) return subtitle;

  const boldParts = subtitle.split(/(\*\*.*?\*\*)/g);

  return boldParts.map(part =>
    part.startsWith('**') ? (
      <strong key={part}>{part.replace(/\*/g, '')}</strong>
    ) : (
      part
    )
  );
};

export const DisputeHighlight = ({ disputeData }) => {
  const formatedDisputeDeadline = dateFormatter(
    disputeData.deadline,
    'DD MMM [às] HH:mm'
  );

  const getDisputesDataPhrases = () => {
    if (disputeData.situation === 'DISPUTE_SITUATION_TYPE_EXPIRED') {
      return {
        title: HIGHLIGHT_TEXTS.expired.title,
        subtitle: HIGHLIGHT_TEXTS.expired.subtitle.replace(
          '$deadline',
          formatedDisputeDeadline
        ),
        deadline: '',
        backGroundColor: colors.red[50]
      };
    }
    if (
      disputeData.situation === 'DISPUTE_SITUATION_TYPE_PENDING' ||
      disputeData.situation === 'DISPUTE_SITUATION_INVALID' ||
      disputeData.situation === 'DISPUTE_SITUATION_TYPE_UNASSIGNED'
    ) {
      if (disputeData.disputeResponsible) {
        return {
          title: HIGHLIGHT_TEXTS.pendingWithResponsible.title,
          subtitle: HIGHLIGHT_TEXTS.pendingWithResponsible.subtitle.replace(
            '$responsible',
            disputeData.disputeResponsible
          ),
          deadline: HIGHLIGHT_TEXTS.pendingWithResponsible.deadline.replace(
            '$deadline',
            formatedDisputeDeadline
          ),
          backGroundColor: colors.yellow[50]
        };
      }
      return {
        title: HIGHLIGHT_TEXTS.pedingWithoutResponsible.title,
        subtitle: HIGHLIGHT_TEXTS.pedingWithoutResponsible.subtitle,
        deadline: HIGHLIGHT_TEXTS.pedingWithoutResponsible.deadline.replace(
          '$deadline',
          formatedDisputeDeadline
        ),
        backGroundColor: colors.yellow[50]
      };
    }
    if (disputeData.situation === 'DISPUTE_SITUATION_TYPE_SOLVED') {
      return {
        title: HIGHLIGHT_TEXTS.solved.title,
        subtitle: HIGHLIGHT_TEXTS.solved.subtitle,
        deadline: '',
        backGroundColor: colors.blue[50]
      };
    }
    return null;
  };

  const phrases = getDisputesDataPhrases();
  const classes = useStyles();

  const tagStatus = renderTranslatedStatus(disputeData);

  return phrases
    ? {
        backGroundColor: phrases.backGroundColor,
        content: (
          <Box
            borderRadius={8}
            px={3}
            py={0}
            bgcolor={phrases.backGroundColor}
            data-testid="dispute-highlight-info"
          >
            {tagStatus ? <Box mb={3}>{tagStatus}</Box> : null}
            <Typography className={classes.smallTitle} variant="subtitle2">
              {phrases.title}
            </Typography>
            <br />
            <Typography style={{ whiteSpace: 'pre-line' }}>
              {renderSubtitle(phrases.subtitle)}
              <strong>{phrases.deadline}</strong>
            </Typography>
          </Box>
        )
      }
    : {
        backGroundColor: null,
        content: null
      };
};
DisputeHighlight.defaultProps = {
  disputeData: null
};
DisputeHighlight.propTypes = {
  disputeData: PropTypes.shape(disputesModel)
};

export default DisputeHighlight;
