export const TEXTS = {
  INCOMPLETE_REGISTER: 'Cadastro incompleto',
  DRIVER_ERROR: error => `
    ${error}
    Verifique e tente de novo.
  `,
  DRIVER_ERROR_TRANSLATE_MAP: {
    'CPF already in use': 'Opa, esse CPF já está cadastrado.',
    'Mobile already in use': 'Opa, esse telefone já está cadastrado.',
    'Email already in use': 'Opa, esse e-mail já está cadastrado.',
    "Couldn't find a unique user: email belongs to one user and mobile belongs to another":
      'Opa, esse cadastro tem informações que pertencem a pessoas diferentes, tente utilizar um novo endereço de e-mail.',
    "Couldn't find a unique user: mobile belongs to multiple users":
      'Opa, esse cadastro tem informações que pertencem a pessoas diferentes, tente utilizar um novo número de celular.',
    'User is a driver':
      'Opa, não deu pra cadastrar. Essa pessoa já está cadastrada com a Loggi.',
    'User is not active':
      'Opa, esse cadastro foi desativado e não dá pra recuperar.',
    'User is staff': 'Eita, essa pessoa é uma funcionária da Loggi.',
    'User is deliverer of another Leve or Carrier':
      'Opa, esse cadastro já existe em outra transportadora.',
    'User does not have access to Leve/Carrier':
      'Opa, esse cadastro tem informações que pertencem a pessoas diferentes, tente utilizar novos dados de email e telefone.',
    'CPF is empty and must be filled':
      'Opa, faltou o CPF dessa pessoa na aba para base. Atualize o cadastro lá e tente de novo.',
    'CPF is different than input':
      'Eita, o CPF informado está diferente das informações já cadastradas dessa pessoa. Confira e tente de novo.'
  },
  EMAIL_WAS_SENT: 'Email was sent',
  SMS_WAS_SENT: 'SMS was sent'
};

export const TRANSPORT_TYPE = {
  TRANSPORT_TYPE_MOTORCYCLE: 'moto',
  TRANSPORT_TYPE_BICYCLE: 'não motorizado',
  TRANSPORT_TYPE_VAN: 'van',
  TRANSPORT_TYPE_CAR: 'carro'
};

export const OPERATIONAL_STATUS_TYPE = {
  cadastro_incompleto: 'Pendente',
  bloqueado: 'Pendente'
};

export const COMPANY_TYPE = {
  COMPANY_TYPE_LOGGI: 'LAST_MILE_COMPANY_TYPE_LOGGI',
  COMPANY_TYPE_LEVE: 'LAST_MILE_COMPANY_TYPE_LEVE',
  COMPANY_TYPE_CARRIER: 'LAST_MILE_COMPANY_TYPE_CARRIER'
};

export const ENVOY_REGISTER_URL = `${
  process.env.REACT_APP_ENVOY_BASE_URL
}/entregador/criar`;
