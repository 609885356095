import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';

import { ITEMS_PER_PAGE_THIRTY } from 'shared/pagination';
import { sortBy } from 'lodash';
import { getDriversService } from '../drivers.service';
import { displaySnackBar } from '../drivers.utils';
import { LIST_ALL_DRIVERS } from '../list/drivers-list.constants';

export const useDriversList = (itemsPerPage = ITEMS_PER_PAGE_THIRTY) => {
  const { enqueueSnackbar } = useSnackbar();

  const [drivers, setDrivers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    numberItems: 0,
    currentPage: 1,
    numberOfPages: 1
  });

  const driversCurrentPage = useMemo(() => {
    const start = (pagination.currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return drivers.slice(start, end);
  }, [drivers, pagination.currentPage, itemsPerPage]);

  const changePage = page => {
    setPagination(prev => ({ ...prev, currentPage: page }));
  };

  const fetchAllDrivers = useCallback(() => {
    setLoading(true);
    setPagination(prev => ({ ...prev, currentPage: 1 }));

    getDriversService(LIST_ALL_DRIVERS)
      .then(response => {
        const users = sortBy(response.users, ['fullName']);
        setDrivers(users);
        setPagination({
          numberItems: users.length,
          currentPage: 1,
          numberOfPages: Math.ceil(users.length / itemsPerPage)
        });
      })
      .catch(displaySnackBar({ enqueueSnackbar }))
      .finally(() => setLoading(false));
  }, [enqueueSnackbar, itemsPerPage]);

  useEffect(() => {
    fetchAllDrivers();
  }, [fetchAllDrivers]);

  return {
    drivers,
    driversCurrentPage,
    pagination,
    loading,
    changePage,
    refetch: fetchAllDrivers
  };
};
