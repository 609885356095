import { Box, Button, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  MODALITY_DATA_BY_SITUATION,
  PENDING_DISPUTE_SITUATIONS
} from 'tracking/disputes/disputes.constants';
import useStyles from 'view/molecules/package-drawer/drawer.style';

import { renderTranslatedStatus } from 'tracking/disputes/disputes.configuration';
import { TEXT } from '../../drawer.constants';

const renderSubtitle = subtitle => {
  if (!subtitle?.includes('**')) return subtitle;

  const boldParts = subtitle.split(/(\*\*.*?\*\*)/g);

  return boldParts.map(part =>
    part.startsWith('**') ? (
      <strong key={part}>{part.replace(/\*/g, '')}</strong>
    ) : (
      part
    )
  );
};

export default function ContentDisputeSection({
  deadline,
  disputeData,
  solvedDate,
  onShowResolveDisputePress
}) {
  const styles = useStyles();

  const isPending = PENDING_DISPUTE_SITUATIONS.includes(disputeData?.situation);
  const tagStatus = renderTranslatedStatus(disputeData);
  const phrases = useMemo(() => {
    const data = MODALITY_DATA_BY_SITUATION[(disputeData?.situation)];
    return {
      ...data,
      subtitle: data.subtitle
        .replace('$deadline', deadline)
        .replace('$solved_date', solvedDate)
    };
  }, [disputeData, deadline, solvedDate]);

  return (
    <Box p={3.5} bgcolor={phrases.backGroundColor}>
      {tagStatus ? <Box mb={3}>{tagStatus}</Box> : null}
      <Typography className={styles.smallTitle} variant="subtitle2">
        {phrases.title}
      </Typography>
      <br />
      <Typography style={{ whiteSpace: 'pre-line' }}>
        {renderSubtitle(phrases.subtitle)}
        <strong>{phrases.deadline}</strong>
      </Typography>
      {isPending && (
        <Button
          data-testid="button-identify-dispute"
          size="small"
          color="primary"
          variant="contained"
          onClick={onShowResolveDisputePress}
          style={{ marginTop: 16 }}
        >
          {TEXT.RESOLVE_DISPUTE_BUTTON}
        </Button>
      )}
    </Box>
  );
}

ContentDisputeSection.propTypes = {
  deadline: PropTypes.string.isRequired,
  disputeData: PropTypes.shape().isRequired,
  solvedDate: PropTypes.string.isRequired,
  onShowResolveDisputePress: PropTypes.func.isRequired
};
