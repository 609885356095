import { Box, Button, Checkbox, FormControlLabel } from '@material-ui/core';
import React from 'react';
import ConfirmDialogComponent from 'shared/confirm-dialog/confirm-dialog.component';
import PropTypes from 'prop-types';
import {
  CONFIRM_REDIRECT_DIALOG,
  REDIRECT_TO_ENVOY_STORAGE_KEY
} from './constants';

export default function ConfirmRedirectDialog({ onConfirm, onCancel }) {
  const [dontShowAgain, setDontShowAgain] = React.useState(false);
  const handleConfirm = () => {
    if (dontShowAgain) {
      localStorage.setItem(REDIRECT_TO_ENVOY_STORAGE_KEY.DONT_SHOW_AGAIN, true);
    }
    onConfirm();
  };

  return (
    <ConfirmDialogComponent
      open
      title={CONFIRM_REDIRECT_DIALOG.title}
      description={CONFIRM_REDIRECT_DIALOG.description}
    >
      <Box
        display="flex"
        width="100%"
        flexDirection="column"
        alignItems="flex-start"
      >
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              inputProps={{ 'data-testid': 'dont-show-again-checkbox' }}
            />
          }
          label={CONFIRM_REDIRECT_DIALOG.checkboxLabel}
          onChange={() => setDontShowAgain(old => !old)}
          value={dontShowAgain}
        />
        <Box
          display="flex"
          width="100%"
          flexDirection={{ xs: 'column', sm: 'row-reverse' }}
        >
          <Box pt={{ xs: 2.5, sm: 0 }} mr={{ xs: 0, sm: 1 }}>
            <Button
              fullWidth
              data-testid="confirm-redirect-primary-button"
              size="large"
              variant="contained"
              color="primary"
              onClick={handleConfirm}
            >
              {CONFIRM_REDIRECT_DIALOG.confirmButtonLabel}
            </Button>
          </Box>
          <Box>
            <Button
              fullWidth
              data-testid="confirm-redirect-cancel-button"
              size="large"
              variant="text"
              color="primary"
              onClick={onCancel}
            >
              {CONFIRM_REDIRECT_DIALOG.cancelButtonLabel}
            </Button>
          </Box>
        </Box>
      </Box>
    </ConfirmDialogComponent>
  );
}

ConfirmRedirectDialog.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};
