import { getSelectedRoutingCode } from 'auth/login/login.service';
import { cardUnifiedReceivePackageService } from 'tracking/card-receive-package/card-receive-package.service';
import { RETURN_TO_BASE_CONTEXT } from 'view/molecules/package-drawer/drawer.constants';

const resolveDisputeService = async barcode => {
  const selectedRoutingCode = getSelectedRoutingCode();

  await cardUnifiedReceivePackageService({
    barcode,
    sortingContextLpn: `${selectedRoutingCode} ${RETURN_TO_BASE_CONTEXT}`
  });
};

export default resolveDisputeService;
