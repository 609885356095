import { colors } from '@loggi/mar';

export const ONE_LOCALSTORAGE_KEY = 'loggione';
export const COVERAGE_PARAMS_LOCALSTORAGE_KEY = `${ONE_LOCALSTORAGE_KEY}_coverage_params`;

// Initial route for coletas
export const TRACKING_ROUTE_INITIAL_STATE =
  '/coletas?status=waiting_pickup,waiting_volume,waiting_confirmation';

// Transport types translations
export const TRANSPORT_TYPES = {
  MOTORCYCLE: 'transportTypes.motorcycle',
  BICYCLE: 'transportTypes.bicycle',
  VAN: 'transportTypes.van',
  CAR: 'transportTypes.car'
};

export const TRANSPORT_TYPE_MAPPER = type => {
  const mapper = {
    1: TRANSPORT_TYPES.MOTORCYCLE,
    2: TRANSPORT_TYPES.BICYCLE,
    3: TRANSPORT_TYPES.VAN,
    4: TRANSPORT_TYPES.CAR,
    TRANSPORT_TYPE_MOTORCYCLE: TRANSPORT_TYPES.MOTORCYCLE,
    TRANSPORT_TYPE_BICYCLE: TRANSPORT_TYPES.BICYCLE,
    TRANSPORT_TYPE_VAN: TRANSPORT_TYPES.VAN,
    TRANSPORT_TYPE_CAR: TRANSPORT_TYPES.CAR
  };
  return mapper[type];
};

export const GET_POSTAL_CODE_COVERAGE_PUBLIC_API_URL = `https://www.loggi.com/beyond/api/shipment/v1/postalcode-coverage`;

export const ZENDESK_HELP_CENTER_URL = 'https://ajuda.loggi.com/hc/pt-br';

export const HELP_CENTER_TYPEFORM = 'https://loggibr.typeform.com/to/Y0plIo';

export const LOGIN_STORAGE_KEYS = {
  REDIRECT_SERVICE: 'service',
  ZENDESK: 'zendesk'
};

export const INTEGRATION_BLING_STORAGE_KEY = `${ONE_LOCALSTORAGE_KEY}_reached_integration_bling`;

export default {
  COVERAGE_PARAMS_LOCALSTORAGE_KEY,
  ZENDESK_HELP_CENTER_URL,
  LOGIN_STORAGE_KEYS,
  ONE_LOCALSTORAGE_KEY,
  TRACKING_ROUTE_INITIAL_STATE,
  INTEGRATION_BLING_STORAGE_KEY
};

export const PAYMENT_STATUS = {
  PAID: {
    color: colors.blue[500],
    label: 'Pagamento recebido'
  },
  PAST_DUE: {
    color: colors.smoke[500],
    label: 'Pagamento em atraso'
  },
  AVAILABLE_FOR_PAYMENT: {
    color: colors.smoke[500],
    label: 'Disponível para pagamento'
  },
  CURRENT: {
    color: colors.root[0],
    borderColor: colors.smoke[500],
    label: 'Fatura atual - Fecha em'
  }
};

export const COMPANY_STATUS_PROTO_VALUES = {
  INVALID: 'COMPANY_STATUS_CODE_INVALID',
  INACTIVE: 'COMPANY_STATUS_CODE_INACTIVE',
  ACTIVE: 'COMPANY_STATUS_CODE_ACTIVE',
  DEFAULTER: 'COMPANY_STATUS_CODE_DEFAULTER',
  OUT_OF_COVERAGE: 'COMPANY_STATUS_CODE_OUT_OF_COVERAGE'
};

export const PACKAGE_DIRECTION_DELIVERY_CODE = 1;
export const PACKAGE_DIRECTION_RETURN_CODE = 2;
export const PACKAGE_DIRECTION_RETURN = 'PACKAGE_DIRECTION_RETURN';
export const PACKAGE_DIRECTION_DELIVERY = 'PACKAGE_DIRECTION_DELIVERY';
export const ENABLE_UNIFIED_PICKUPS = 'enable_unified_pickups';

export const REDISPATCH_PICKUP_DEADLINE_DAYS = 7;

export const LAST_MILE_COMPANY_TYPE_LOGGI = 'LAST_MILE_COMPANY_TYPE_LOGGI';

export const estimatedPackagesOptions = {
  249: 'Menos de 250 pacotes',
  1999: 'De 250 a 2000 pacotes',
  2000: 'Mais de 2000 pacotes'
};

export const RETURN_DEADLINE_DAYS = 20;
