import React, { useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Box, Grid, Button, Paper, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { ACTIONS } from 'auth/access-control/access-control.rules';
import { Can } from 'auth/access-control';
import { SummaryContext } from 'tracking/template/summary.context';
import { UsersManagementContext } from 'users-management/users-management.context';
import ConfigurableTable, {
  ConfigurableTableSkeleton
} from 'shared/configurable-table';

import OPERATOR_MODEL from 'users-management/operators/operators.model';
import { ReactComponent as EmptyStateSearchImage } from 'images/illustra-packages.svg';
import EmptyState from 'users-management/drivers/list/empty-state/drivers-list-empty-state.component';

import { SearchInput } from 'users-management/components/search-input';
import CONFIGURABLE_TABLE from './operators.configuration';
import useStyles from './operators-list.styles';
import TEXTS from './operators-list.constants';

function OperatorListComponent({
  users,
  onOpenInvitaion,
  onOpenDetail,
  loading
}) {
  const { setSummary } = useContext(SummaryContext);
  const { setCardContent } = useContext(UsersManagementContext);

  const [search, setSearch] = React.useState('');
  const filteredUsers = useMemo(() => {
    if (!search) return users;
    const searchLower = search.toLowerCase();
    const cleanedSearch = searchLower.replace(/[.|\-|+|(|)|\s]/g, '');
    return users?.filter(user => {
      return (
        user.fullName?.toLowerCase().includes(searchLower) ||
        user.email?.toLowerCase().includes(searchLower) ||
        user.lastMileOperator?.cpf?.includes(cleanedSearch) ||
        user?.mobileNumber?.includes(cleanedSearch)
      );
    });
  }, [users, search]);

  const classes = useStyles();

  const FS_CONFIG_TABLE = (() => CONFIGURABLE_TABLE)();

  useEffect(() => {
    setSummary(
      <>
        <Typography variant="h4">
          <strong>{TEXTS.SUMMARY_TITLE}</strong>
        </Typography>
        {!loading && (
          <Typography variant="h4">{`${users.length} ${
            TEXTS.SUMMARY_SUBTITLE
          }`}</Typography>
        )}
        {loading && (
          <Box pt={0.5} pb={0.5}>
            <Skeleton variant="rect" width="150px" height="34px" />
          </Box>
        )}
      </>
    );
  }, [setSummary, users, loading]);

  useEffect(() => {
    setCardContent(
      <Grid container>
        <Grid item xs>
          <Typography variant="h6" className={classes.lineheight}>
            <em>{TEXTS.TEXT_TO_INVITE}</em>
          </Typography>
        </Grid>
        <Grid item>
          <Can actions={[ACTIONS.USER_MANAGEMENT_BASE_WRITE]}>
            <Button
              color="primary"
              variant="contained"
              data-testid="open-from-btn"
              onClick={onOpenInvitaion}
            >
              {TEXTS.BTN_TO_INVITE}
            </Button>
          </Can>
        </Grid>
      </Grid>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCardContent]);

  const renderContent = () => {
    if (loading) return <ConfigurableTableSkeleton />;

    if (!users?.length) {
      return <EmptyState emptyText="Nenhum operador cadastrado" />;
    }

    if (!filteredUsers?.length) {
      return (
        <EmptyState
          emptyText={`Nenhum resultado para a busca "${search}"`}
          illustration={<EmptyStateSearchImage />}
        />
      );
    }

    return (
      <ConfigurableTable
        data={filteredUsers}
        columns={FS_CONFIG_TABLE}
        onRowSelected={onOpenDetail}
      />
    );
  };

  return (
    <>
      <Paper
        component={Box}
        elevation={10}
        flexGrow={1}
        mb={6}
        minHeight={44}
        p={5.5}
      >
        <SearchInput
          value={search}
          onSubmit={newSearch => setSearch(newSearch)}
        />
        {renderContent()}
      </Paper>
    </>
  );
}

OperatorListComponent.propTypes = {
  users: PropTypes.arrayOf(OPERATOR_MODEL).isRequired,
  onOpenInvitaion: PropTypes.func,
  onOpenDetail: PropTypes.func,
  loading: PropTypes.bool
};

OperatorListComponent.defaultProps = {
  onOpenInvitaion: () => {},
  onOpenDetail: () => {},
  loading: false
};

export default OperatorListComponent;
