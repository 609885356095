import { Box, List } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

import dateFormatter from 'shared/formatters/date';

import {
  DisputeHelpCenter,
  HowToUseOption,
  PackageHistoryItem,
  ProofOfDeliveryItem
} from '../drawer-detail/drawer-detail.component';
import {
  HeaderDisputeSection,
  AddressDisputeSection,
  ContentDisputeSection
} from './sections';

export default function DrawerDisputeDetailV2({
  disputeData,
  packageInfo,
  onShowResolveDisputePress,
  onHowToUseDisputePress,
  onPackageHistoryPress,
  onProofOfDeliveryPress
}) {
  const isSolved = disputeData?.situation === 'DISPUTE_SITUATION_TYPE_SOLVED';

  const formattedCreatedDate = dateFormatter(
    disputeData?.created,
    'DD MMM [às] HH:mm'
  );
  const formattedDeadline = dateFormatter(
    disputeData?.deadline,
    'DD MMM [às] HH:mm'
  );
  const formattedSolvedDate = dateFormatter(
    disputeData?.solvedTime,
    'DD MMM [às] HH:mm'
  );

  return (
    <Box data-testid="drawer-dispute-detail-component">
      <HeaderDisputeSection
        disputeSolvedDate={formattedSolvedDate}
        disputeCreatedDate={formattedCreatedDate}
        isDisputeSolved={isSolved}
        packageInfo={packageInfo}
        situation={disputeData.situation}
      />
      <ContentDisputeSection
        onShowResolveDisputePress={onShowResolveDisputePress}
        deadline={formattedDeadline}
        disputeData={disputeData}
        solvedDate={formattedSolvedDate}
      />
      <AddressDisputeSection packageInfo={packageInfo} />

      <List data-testid="dispute-detail-actions" disablePadding>
        <PackageHistoryItem
          packageInfo={packageInfo}
          packageHistory={onPackageHistoryPress}
        />
        {packageInfo?.proofOfDeliveryContent && (
          <ProofOfDeliveryItem proofOfDelivery={onProofOfDeliveryPress} />
        )}
        <HowToUseOption howToUseDispute={onHowToUseDisputePress} />
        <DisputeHelpCenter />
      </List>
    </Box>
  );
}

DrawerDisputeDetailV2.propTypes = {
  onShowResolveDisputePress: PropTypes.func.isRequired,
  onHowToUseDisputePress: PropTypes.func.isRequired,
  onPackageHistoryPress: PropTypes.func.isRequired,
  onProofOfDeliveryPress: PropTypes.func.isRequired,
  disputeData: PropTypes.shape().isRequired,
  packageInfo: PropTypes.shape().isRequired
};
