import { database } from 'infra/firebase/firebase.init';
import { useEffect, useState } from 'react';
import { getSelectedBase } from 'profile/profile.service';
import { SWITCHES_REALTIME } from '../../view/constants';

const configRef = database.ref(
  `feature_switches/${SWITCHES_REALTIME.envoyBasesConfig}`
);

const isConfigActiveForSelectedBase = (config, data) => {
  try {
    if (!data) return false;

    const LMCDC = getSelectedBase();
    // eslint-disable-next-line camelcase
    const routingCode = LMCDC?.distribution_center?.routing_code?.toUpperCase();
    const currentConfigs = data[routingCode] ?? data['*'];

    return Boolean(currentConfigs[config]);
  } catch {
    return false;
  }
};

export const useIsEnvoyConfigActive = config => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const onError = () => setIsActive(false);
    const onValueChange = snapshot => {
      const data = snapshot.val();
      setIsActive(isConfigActiveForSelectedBase(config, data));
    };

    configRef.on('value', onValueChange, onError);

    return () => {
      configRef.off('value', onValueChange);
    };
  }, [config]);

  return isActive;
};
