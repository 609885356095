export const IDENTIFY_PACKAGE_DISPUTE_TEXTS = {
  TITLE: 'Identificar pacote',
  PLACEHOLDER: 'Bipe ou digite o código',
  DISPUTE_RESOLVED: 'Pacote bipado com sucesso, a acareação foi resolvida.',
  INVALID_BARCODE_ERROR:
    'Não foi possível identificar o pacote. Bipe ou digite novamente o código do pacote.'
};

export const EMPTY_STATE_TEXTS = {
  TITLE: 'Nenhum pacote identificado',
  DESCRIPTION: 'Identifique o pacote para resolver a acareação.'
};
