import React, { useMemo } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { Can } from 'auth/access-control';
import PropTypes from 'prop-types';
import { ACTIONS } from 'auth/access-control/access-control.rules';
import useStyles from '../../drawer.style';
import { TEXT, unitLoadMapper } from '../../drawer.constants';

export default function ChunkHeaderPackageComponent({
  isAtFinishedScreen,
  showBtnCalculateDeadline,
  setDisableBtnCalculatePromisedDate,
  forceCreatePromisedDate,
  disableBtnCalculatePromisedDate,
  packageInfo,
  isDisputeSolved,
  isDisputePending
}) {
  const classes = useStyles();

  const headerText = useMemo(() => {
    if (isDisputePending) return '';
    if (isDisputeSolved) return 'Finalizado';
    return unitLoadMapper(packageInfo, isAtFinishedScreen);
  }, [isDisputeSolved, isDisputePending, packageInfo, isAtFinishedScreen]);

  return (
    <Box pb={2.5} display="flex" justifyContent="space-between">
      <Typography variant="h4" className={classes.bolder}>
        {headerText}
      </Typography>
      {showBtnCalculateDeadline() && (
        <Can actions={[ACTIONS.BTN_CALCULATE_PROMISED_DATE]}>
          <Button
            variant="outlined"
            color="primary"
            size="medium"
            onClick={() => {
              setDisableBtnCalculatePromisedDate(true);
              forceCreatePromisedDate();
            }}
            data-testid="btn-calculate-promised-dated"
            disabled={disableBtnCalculatePromisedDate}
          >
            {TEXT.CALCULATE_DEADLINE}
          </Button>
        </Can>
      )}
    </Box>
  );
}

ChunkHeaderPackageComponent.propTypes = {
  isAtFinishedScreen: PropTypes.bool.isRequired,
  showBtnCalculateDeadline: PropTypes.func.isRequired,
  setDisableBtnCalculatePromisedDate: PropTypes.func.isRequired,
  forceCreatePromisedDate: PropTypes.func.isRequired,
  disableBtnCalculatePromisedDate: PropTypes.bool.isRequired,
  packageInfo: PropTypes.shape({}).isRequired,
  isDisputeSolved: PropTypes.bool.isRequired,
  isDisputePending: PropTypes.bool.isRequired
};
