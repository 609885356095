export const REDIRECT_TO_ENVOY_STORAGE_KEY = {
  DONT_SHOW_AGAIN: 'DONT_SHOW_REDIRECT_DIALOG_CONFIRMATION_AGAIN'
};

export const CONFIRM_REDIRECT_DIALOG = {
  title: 'Você gostaria de cadastrar uma pessoa para entregas e/ou coletas?',
  description:
    "Para cadastrar uma pessoa para realizar entregas ou coletas, você será redirecionado para o 'Envoy'. Lá, será necessário concluir o cadastro completo do usuário, seguindo as orientações fornecidas.",
  confirmButtonLabel: 'Cadastrar',
  cancelButtonLabel: 'Cancelar',
  checkboxLabel: 'Não exibir novamente'
};
