import {
  Box,
  Grid,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core';
import React from 'react';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import PropTypes from 'prop-types';
import useStyles from '../../drawer.style';

export default function DrawerListItem({ label, onClick }) {
  const classes = useStyles();
  return (
    <ListItem
      button
      divider
      onClick={onClick}
      classes={{ root: classes.item }}
      disableGutters
    >
      <Grid container alignItems="center" justify="flex-end">
        <Grid className={classes.listItemLabel}>
          <Box ml={3.5} my={2.5}>
            <ListItemText
              primary={
                <Typography variant="body1" data-testid="package-history-item">
                  {label}
                </Typography>
              }
            />
          </Box>
        </Grid>
        <Grid>
          <Box
            ml={2}
            mr={3.5}
            my={2.5}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <ArrowForwardIosIcon
              className={classes.fontSizeIcon}
              color="primary"
              size="small"
            />
          </Box>
        </Grid>
      </Grid>
    </ListItem>
  );
}

DrawerListItem.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};
