import { colors } from '@material-ui/core';

export const STATUS_DELIVERED = '2';
export const STATUS_CODE_PICKUP = '3';
export const STATUS_CODE_GOING_TO_DELIVER = '5';
export const STATUS_RECIPIENT_DENIED = '11';
export const STATUS_RECIPIENT_UNAVAILABLE = '12';
export const STATUS_RECIPIENT_WRONG_ADDRESS = '13';
export const STATUS_PACKAGE_DAMAGED = '14';
export const STATUS_PACKAGE_LOST = '15';
export const STATUS_PACKAGE_STOLEN = '20';
export const STATUS_AWAITING_RESOLUTION = '28';
export const STATUS_READY_TO_RESEND = '29';
export const STATUS_READY_TO_RETURN = '30';
export const STATUS_CUSTOMER_PICKUP = '81';
export const STATUS_CONFIRMED = '84';
export const STATUS_NO_ATTEMPT = '89';
export const STATUS_DEFAULT = '0';

export const STATUS_ITINERARY_ALLOCATING = 'allocating';
export const STATUS_ITINERARY_WAITING_SLO = 'waiting_slo';
export const STATUS_ITINERARY_ACCEPTED = 'accepted';
export const STATUS_ITINERARY_WAITING_PICK_UP = 'waiting_pick_up';
export const STATUS_ITINERARY_STARTED = 'started';
export const STATUS_ITINERARY_ONGOING = 'ongoing';
export const STATUS_ITINERARY_FINISHED = 'finished';
export const STATUS_ITINERARY_CANCELLED = 'cancelled';
export const STATUS_ITINERARY_REQUIRES_VERIFICATION = 'requires_verification';
export const STATUS_ITINERARY_AWAITING_COMPLETION = 'awaiting_completion';
export const STATUS_ITINERARY_DROPPED = 'dropped';
export const STATUS_ITINERARY_DROPPED_THEN_CANCELLED = 'droppedThenCancelled';
export const STATUS_ITINERARY_ERROR = 'error';
export const STATUS_ITINERARY_MERGED = 'merged';

export const STATUS_DISPUTE_PACKAGE_NOT_FOUND = 'dispute-package-not-found';
export const STATUS_DISPUTE_PACKAGE_FOUND = 'dispute-package-found';

const ICON_SIZE = 18;

export const MAPPER_STATUS = {
  [STATUS_DEFAULT]: {
    style: { color: colors.grey[900], fontSize: ICON_SIZE },
    text: 'Não Disponivel'
  },
  [STATUS_NO_ATTEMPT]: {
    style: { color: colors.blue[500], fontSize: ICON_SIZE },
    text: 'Sem tentativa',
    value: STATUS_NO_ATTEMPT
  },
  [STATUS_RECIPIENT_UNAVAILABLE]: {
    style: { color: colors.grey[300], fontSize: ICON_SIZE },
    text: 'Destinatário ausente',
    value: STATUS_RECIPIENT_UNAVAILABLE
  },
  [STATUS_RECIPIENT_DENIED]: {
    style: { color: colors.grey[600], fontSize: ICON_SIZE },
    text: 'Recusado pelo destinatário',
    value: STATUS_RECIPIENT_DENIED
  },
  [STATUS_RECIPIENT_WRONG_ADDRESS]: {
    style: { color: colors.grey[600], fontSize: ICON_SIZE },
    text: 'Endereço Errado',
    value: STATUS_RECIPIENT_WRONG_ADDRESS
  },
  [STATUS_DELIVERED]: {
    style: { color: colors.green[500], fontSize: ICON_SIZE },
    text: 'Entregue',
    value: STATUS_DELIVERED
  },
  [STATUS_PACKAGE_DAMAGED]: {
    style: { color: colors.blueGrey[900], fontSize: ICON_SIZE },
    text: 'Mercadoria avariada',
    value: STATUS_PACKAGE_DAMAGED
  },
  [STATUS_PACKAGE_LOST]: {
    style: { color: colors.blueGrey[900], fontSize: ICON_SIZE },
    text: 'Pacote extraviado',
    value: STATUS_PACKAGE_LOST
  },
  [STATUS_PACKAGE_STOLEN]: {
    style: { color: colors.blueGrey[900], fontSize: ICON_SIZE },
    text: 'Roubo / Furto',
    value: STATUS_PACKAGE_STOLEN
  },
  [STATUS_AWAITING_RESOLUTION]: {
    style: { color: colors.blueGrey[900], fontSize: ICON_SIZE },
    text: 'Aguardando tratativa',
    value: STATUS_AWAITING_RESOLUTION
  },
  [STATUS_READY_TO_RESEND]: {
    style: { color: colors.blueGrey[900], fontSize: ICON_SIZE },
    text: 'Aguardando reenvio',
    value: STATUS_READY_TO_RESEND
  },
  [STATUS_READY_TO_RETURN]: {
    style: { color: colors.blueGrey[900], fontSize: ICON_SIZE },
    text: 'Aguardando devolução',
    value: STATUS_READY_TO_RETURN
  },
  [STATUS_CONFIRMED]: {
    style: { color: colors.blueGrey[900], fontSize: ICON_SIZE },
    text: 'Conferido',
    value: STATUS_CONFIRMED
  },
  [STATUS_CUSTOMER_PICKUP]: {
    style: { color: colors.blueGrey[900], fontSize: ICON_SIZE },
    text: 'Coletado no CD do cliente',
    value: STATUS_CUSTOMER_PICKUP
  },
  [STATUS_CODE_PICKUP]: {
    style: { color: colors.blue[500], fontSize: ICON_SIZE },
    text: 'Retirado',
    value: STATUS_CODE_PICKUP
  },
  [STATUS_CODE_GOING_TO_DELIVER]: {
    style: { color: colors.blue[500], fontSize: ICON_SIZE },
    text: 'Em rota de entrega',
    value: STATUS_CODE_GOING_TO_DELIVER
  },
  [STATUS_ITINERARY_FINISHED]: {
    style: { color: colors.green[500], fontSize: ICON_SIZE },
    text: 'Terminada',
    value: STATUS_ITINERARY_FINISHED
  },
  [STATUS_ITINERARY_ACCEPTED]: {
    style: { color: colors.grey[600], fontSize: ICON_SIZE },
    text: 'Aceita',
    value: STATUS_ITINERARY_ACCEPTED
  },
  [STATUS_ITINERARY_STARTED]: {
    style: { color: colors.blue[500], fontSize: ICON_SIZE },
    text: 'Iniciada',
    value: STATUS_ITINERARY_STARTED
  },
  [STATUS_ITINERARY_WAITING_PICK_UP]: {
    style: { color: colors.grey[600], fontSize: ICON_SIZE },
    text: 'Aguardando aceite',
    value: STATUS_ITINERARY_WAITING_PICK_UP
  },
  [STATUS_ITINERARY_CANCELLED]: {
    style: { color: colors.grey[900], fontSize: ICON_SIZE },
    text: 'Cancelada',
    value: STATUS_ITINERARY_CANCELLED
  },
  [STATUS_ITINERARY_ALLOCATING]: {
    style: { color: colors.grey[400], fontSize: ICON_SIZE },
    text: 'Em alocação',
    value: STATUS_ITINERARY_ALLOCATING
  },
  [STATUS_ITINERARY_WAITING_SLO]: {
    style: { color: colors.grey[400], fontSize: ICON_SIZE },
    text: 'Aguardando alocação',
    value: STATUS_ITINERARY_WAITING_SLO
  },
  [STATUS_ITINERARY_ONGOING]: {
    style: { color: colors.blue[500], fontSize: ICON_SIZE },
    text: 'Em execução',
    value: STATUS_ITINERARY_ONGOING
  },
  [STATUS_ITINERARY_REQUIRES_VERIFICATION]: {
    style: { color: colors.grey[900], fontSize: ICON_SIZE },
    text: 'Requer verificação',
    value: STATUS_ITINERARY_REQUIRES_VERIFICATION
  },
  [STATUS_ITINERARY_AWAITING_COMPLETION]: {
    style: { color: colors.grey[900], fontSize: ICON_SIZE },
    text: 'Aguardando finalização',
    value: STATUS_ITINERARY_AWAITING_COMPLETION
  },
  [STATUS_ITINERARY_DROPPED]: {
    style: { color: colors.grey[900], fontSize: ICON_SIZE },
    text: 'Perdida',
    value: STATUS_ITINERARY_DROPPED
  },
  [STATUS_ITINERARY_DROPPED_THEN_CANCELLED]: {
    style: { color: colors.grey[900], fontSize: ICON_SIZE },
    text: 'Perdida e cancelada',
    value: STATUS_ITINERARY_DROPPED_THEN_CANCELLED
  },
  [STATUS_ITINERARY_ERROR]: {
    style: { color: colors.grey[900], fontSize: ICON_SIZE },
    text: 'Problema',
    value: STATUS_ITINERARY_ERROR
  },
  [STATUS_ITINERARY_MERGED]: {
    style: { color: colors.grey[900], fontSize: ICON_SIZE },
    text: 'Pedido com pacotes mergeados',
    value: STATUS_ITINERARY_MERGED
  },
  [STATUS_DISPUTE_PACKAGE_NOT_FOUND]: {
    style: { color: colors.red[700], fontSize: ICON_SIZE },
    text: 'Pacote não encontrado'
  },
  [STATUS_DISPUTE_PACKAGE_FOUND]: {
    style: { color: colors.green[500], fontSize: ICON_SIZE },
    text: 'Pacote encontrado'
  }
};
